import { GeneralErrorBoundary } from '@/components/general-error-boundary'
import type { SEOHandle } from '@nasa-gcn/remix-seo'

export const handle: SEOHandle = {
  getSitemapEntries: () => null,
}

export function loader() {
  throw new Response('Not found', { status: 404 })
}

export default GeneralErrorBoundary

export const ErrorBoundary = GeneralErrorBoundary
